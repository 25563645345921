<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Create Quality Check</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <v-radio-group
          @change="clearData"
          v-model="formData.count_category"
          row
          class="mb-4 mt-n1"
        >
          <v-radio label="Case" value="case" color="indigo"></v-radio>
          <v-radio label="Location" value="location" color="indigo"></v-radio>
        </v-radio-group>

        <div>
          <v-select
            v-model="formData.assigned_to"
            label="Assign User"
            item-text="text"
            item-value="index"
            :items="assignableUsers"
            clearable
            dense
            outlined
          ></v-select>
        </div>

        <div v-if="formData.count_category === 'case'">
          <v-combobox
            v-model="formData.count_category_value"
            label="Cases"
            outlined
            chips
            small-chips
            multiple
            deletable-chips
            dense
            clearable
            class="poppins"
            append-icon="mdi-barcode-scan"
            :error-messages="countCategoryErrors"
            @input="$v.formData.count_category_value.$touch()"
            @blur="$v.formData.count_category_value.$touch()"
          />
        </div>

        <div v-else-if="formData.count_category === 'location'">
          <v-autocomplete
            v-model="formData.count_category_value"
            outlined
            dense
            small-chips
            cache-items
            multiple
            label="Locations"
            item-text="name"
            item-value="id"
            append-icon="mdi-barcode-scan"
            :items="suggestedLocations"
            :loading="locationLoading"
            :search-input.sync="locationSearchText"
            :error-messages="countCategoryErrors"
            @update:search-input="searchLocations"
            @input="$v.formData.count_category_value.$touch()"
            @blur="$v.formData.count_category_value.$touch()"
          ></v-autocomplete>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            @click="submit"
            type="submit"
            class="btn btn--export-filter mr-3 px-5 py-3 ls1"
          >
            Create
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import {
  required,
  // requiredIf,
} from "vuelidate/lib/validators";
import { UPDATE_TABLE_DATA } from "@/core/services/store/standardQualityCheck.module.js";
import ApiService from "@/core/services/api.service";

export default {
  name: "CreateQualityCheckModal",
  mixins: [validationMixin],
  props: ["pageLoading"],
  validations: {
    formData: {
      // assigned_to: { required },
      count_category_value: { required },
    },
  },
  components: {},
  data: () => ({
    dialog: false,
    formData: {
      assigned_to: null,
      count_category: "case",
      count_category_value: [],
    },
    locationSearchText: null,
    locationLoading: false,
    suggestedLocations: [],
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clearData();
      }
      this.formData.count_category = "case";
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    clearData() {
      this.formData = {
        // count_category: "case",
        ...this.formData,
        assigned_to: null,
        count_category_value: [],
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.pageLoading(true);

      const payload = {
        warehouse_id: this.selectedWarehouse,
        ...this.formData,
      };
      ApiService.post(
        "/warehouse_management/standard_quality_checks/store",
        payload
      )
        .then(({ data }) => {
          if (data.total_error) {
            let message = "<div>";
            data.messages.map((mes) => {
              message = message + `<div>${mes}</div>`;
            });
            message = message + "</div>";
            Swal.fire({
              title: `Response`,
              html: `${message}`,
              icon: "warning",
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              title: `Success`,
              html: `QC created successfully`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            warehouse_id: this.selectedWarehouse,
          });
          this.toggleModal();
        })
        .finally(() => {
          this.pageLoading(false);
        });
    },
    searchLocations() {
      this.locationLoading = true;
      const payload = {
        q: this.locationSearchText,
        filters: {
          warehouse_id: this.selectedWarehouse,
        },
      };
      ApiService.post(`/reports/reportBinOccupancy/search-location`, payload)
        .then(({ data }) => {
          this.suggestedLocations = data.locations;
        })
        .then(() => {
          this.locationLoading = false;
        });
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    assignableUsers: function () {
      return this.$store.getters.getSTANDARDQUALITYCHECKAssignableUsers;
    },
    // assignToErrors: function () {
    //   return this.handleFormValidation("assigned_to");
    // },
    countCategoryErrors: function () {
      return this.handleFormValidation("count_category_value");
    },
  },
};
</script>
