<template>
  <v-card class="mt-4">
    <v-toolbar color="primary h5 text-white">{{ item.title }}</v-toolbar>
    <v-card-text class="pa-4">
      <v-data-table
        disable-sort
        :headers="item.data.header"
        :items="getItems"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        class="elevation-0"
        :footer-props="{ 'items-per-page-options': item_per_page_list }"
      >
        ></v-data-table
      >
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "PopUpDataTable",
  props: ["item"],
  data: () => ({
    total: 0,
    options: {},
    loading: true,
    items: [],
    item_per_page_list: [20, 30, 40, 50],
  }),
  mounted() {
    this.getDataFromApi();
  },
  computed: {
    getItems() {
      return this.items;
    },
    itemForAction() {
      return this.$store.getters.getItemForAction;
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      let payload = {
        page: page,
        per_page: itemsPerPage,
        id: this.itemForAction.id,
      };

      ApiService.post(this.item.url, payload)
        .then((response) => {
          this.total = response.data.data.total;
          this.items = response.data.data.data;

          this.dialog = false;
        })
        .catch(() => {
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    item: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
